import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import BlockTitle from '@/components/atoms/BlockTitle';
import FootNote from '@/components/atoms/FootNote';
import UmerBox from '@/components/atoms/UmerBox';
import { OptoBarChart } from '@/components/organisms/OptoGraphs';
import Options, {
    autoTimeScale,
    categoryScale,
    chartTitle,
    minMax,
    formatAxsis,
    weightAndLengthUnitConverter,
    TypeNames,
    startYAxisAtZero,
    noDots,
} from '@/components/organisms/OptoGraphs/optionsModifiers';
import {
    defaultBarChartOptions,
    last500LaksvelToBarChartMapper,
} from '@/components/organisms/OptoGraphs/OptoBarChart';
import {
    defaultLineChartOptions,
    OptoMixedChart,
} from '@/components/organisms/OptoGraphs/OptoMixedChart';
import { DailyLaksvelMapper } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChartMappers';
import useLaksvel from '@/contexts/laksvel-context';

import { LaksvelRouterPageProps } from '../LaksvelContainerRouter';

import styles from '../Laksvel.module.scss';

const FinnCondition: FunctionComponent<LaksvelRouterPageProps> = () => {
    const { t } = useTranslation();

    const { loading, laksvelData, averageLaksvelData, footNote } = useLaksvel();

    const last500Mapped = last500LaksvelToBarChartMapper({
        data: averageLaksvelData,
        inactiveSetLegend: [],
        property: 'fin_damage',
    });

    return (
        <>
            <div className={styles.laksvelPageContentGrid_50_100}>
                <UmerBox>
                    <figure>
                        <OptoBarChart
                            chartData={last500Mapped}
                            chartName={t('Status')}
                            loading={loading}
                            chartOptions={Options(defaultBarChartOptions).modify(
                                chartTitle({ title: t('Status') }),
                                categoryScale({
                                    labels: last500Mapped.labels as string[] | undefined,
                                }),
                                minMax({ min: 0, max: 100 }),
                                formatAxsis({
                                    axsisID: 'y',
                                    tickType: 'percent',
                                    descimals: 0,
                                })
                            )}
                        />
                    </figure>
                    <FootNote>{footNote}</FootNote>
                </UmerBox>
                <UmerBox>
                    <OptoMixedChart
                        chartData={DailyLaksvelMapper({
                            data: loading ? [] : laksvelData,
                            laksvelAttribute: 'fin_damage',
                        })}
                        actionButtons={{
                            copyAsPng: true,
                            resetZoom: true,
                            downloadAsPng: true,
                            fullScreen: true,
                            timeScaleSwitch: false,
                        }}
                        chartName={'fin_damage'}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            chartTitle({ title: t('Development') }),
                            autoTimeScale({}),
                            weightAndLengthUnitConverter({
                                type: TypeNames.percent,
                                fromUnit: '%',
                                toUnit: '%',
                            }),
                            startYAxisAtZero({}),
                            noDots(),
                            minMax({ min: 0, max: 100 }),
                            formatAxsis({
                                axsisID: 'y',
                                tickType: TypeNames.percent,
                                descimals: 0,
                            })
                        )}
                        loading={loading}
                    />
                </UmerBox>
                <UmerBox>
                    <BlockTitle heading={'Detections'} />

                    {/*
                                            <ImageViewContainer
                                key="detections"
                                instanceKey="detections"
                                isOptoScaleAdmin={true}
                                isColor={true}
                                onCommitImageFeedbackDto={() => {}}
                                images={[]}
                            />
                            <div>
                                <FishHealthFilter
                                    filterStateList={[]}
                                    onFilterGroupChange={() => {}}
                                />
                            </div>
                                
                                */}
                </UmerBox>
            </div>
        </>
    );
};

export default FinnCondition;
export { FinnCondition };
