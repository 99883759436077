import React from 'react';

import { OptoTooltip } from '@/components/atoms/OptoTooltip';

import styles from './IconButton.module.scss';

export interface IconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    children?: React.ReactNode;
    buttonText?: string;
    onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    onlyIcon?: boolean;
    variant?: 'primary' | 'secondary' | 'danger' | 'table';
    iconPos?: 'left' | 'right';
    size?: 'small' | 'medium' | 'large';
    name?: string;
    className?: string;
    title?: string;
    tooltip?: string;
    ref?: React.Ref<HTMLButtonElement>;
    type?: 'button' | 'submit' | 'reset' | null | undefined;
}

const IconButton = ({
    children = null,
    buttonText = 'icon button',
    onClick = () => {},
    disabled = false,
    variant = 'primary',
    onlyIcon = false,
    iconPos = 'left',
    size = 'medium',
    type = 'button',
    tooltip = '',
}: IconButtonProps) => {
    const useTooltip = tooltip.length > 0;
    return useTooltip ? (
        <OptoTooltip nub="down-left" content={tooltip}>
            <div className={styles.iconbutton}>
                <button
                    data-size={size}
                    data-iconpos={iconPos}
                    data-icononly={onlyIcon}
                    data-variant={variant}
                    type={type ? type : 'button'}
                    onClick={onClick}
                    disabled={disabled}>
                    <div>
                        {children && <span>{children}</span>}
                        <span data-icononly={onlyIcon} data-type="text">
                            {buttonText}
                        </span>
                    </div>
                </button>
            </div>
        </OptoTooltip>
    ) : (
        <div className={styles.iconbutton}>
            <button
                data-size={size}
                data-iconpos={iconPos}
                data-icononly={onlyIcon}
                data-variant={variant}
                type={type ? type : 'button'}
                onClick={onClick}
                disabled={disabled}>
                <div>
                    {children && <span>{children}</span>}
                    <span data-icononly={onlyIcon} data-type="text">
                        {buttonText}
                    </span>
                </div>
            </button>
        </div>
    );
};

export default IconButton;
export { IconButton };
