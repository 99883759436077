import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    UncontrolledDropdown,
} from 'reactstrap';
import { t } from 'i18next';

import { HomeIcon } from '@/components/atoms/icons/HomeIcon';
import Loader from '@/components/atoms/Loader';
import { ClientInfo, OverviewCage, OverviewLocation } from '@/services/types';

import styles from './OptoBreadCrumb.module.scss';

const compareCageNames = (a: string, b: string) => {
    const norwegianSpecialLetters = ['æ', 'ø', 'å'];

    const isNumericA = /^\d+$/.test(a); // checks if the string a is made up entirely of numbers
    const isNumericB = /^\d+$/.test(b);

    if (isNumericA && isNumericB) {
        return Number(a) - Number(b); // Compare numerically
    }

    if (isNumericA) return -1; // Put numeric values first
    if (isNumericB) return 1;

    const aFirstChar = a[0].toLowerCase();
    const bFirstChar = b[0].toLowerCase();

    const aIsSpecial = norwegianSpecialLetters.includes(aFirstChar);
    const bIsSpecial = norwegianSpecialLetters.includes(bFirstChar);

    if (aIsSpecial && bIsSpecial) {
        return a.localeCompare(b); // Compare special letters alphabetically
    }

    if (aIsSpecial) return -1; // Special letters first
    if (bIsSpecial) return 1;

    return a.localeCompare(b); // Compare alphabetically
};

export interface OptoBreadCrumbClient {
    id: number;
    name: string;
}

export interface OptoBreadCrumbProps {
    client?: ClientInfo;
    clients: OptoBreadCrumbClient[];
    locationId?: number;
    cageId?: number;
    locations?: OverviewLocation[];
    isLoading: boolean;
    lastUrlPart: string;
    showHomeButton?: boolean;
}

const OptoBreadCrumb = ({
    client,
    clients,
    locationId,
    cageId,
    locations,
    isLoading,
    lastUrlPart,
    showHomeButton = true,
}: OptoBreadCrumbProps) => {
    const history = useHistory();

    const [clientDropdownOpen, setClientDropdownOpen] = useState(false);
    const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
    const [cageDropdownOpen, setCageDropdownOpen] = useState(false);

    const [currentLocation, setCurrentLocation] = useState<OverviewLocation>(null);
    const [currentCage, setCurrentCage] = useState<OverviewCage>(null);

    const [clientNameFilter, setClientNameFilter] = useState('');

    const [clientsFiltredAndSortedAlphabetically, setClientsFiltredAndSortedAlphabetically] =
        useState<OptoBreadCrumbClient[]>([]);

    const placeholderText = `${t('Filter')} ${t('Clients').toLowerCase()}`;

    const lastPartToNiceNameMapper = {
        '/reports/v2': 'Reporting.Reporting',
        '/bioscope-image': 'Images',
        '/lice-count': 'Lice',
    };

    const [locationsSortedByStatus, setLocationsSortedByStatus] = useState<{
        active: OverviewLocation[];
        inactive: OverviewLocation[];
    }>({ active: [], inactive: [] } as {
        active: OverviewLocation[];
        inactive: OverviewLocation[];
    });

    const [cagesSortedByStatus, setCagesSortedByStatus] = useState<{
        active: OverviewCage[];
        inactive: OverviewCage[];
        historical: OverviewCage[];
    }>({ active: [], inactive: [], historical: [] } as {
        active: OverviewCage[];
        inactive: OverviewCage[];
        historical: OverviewCage[];
    });

    useEffect(() => {
        if (clients) {
            const filteredClients = clients
                .filter((client) =>
                    client.name.toLowerCase().includes(clientNameFilter.toLowerCase())
                )
                .sort((a, b) => a.name.localeCompare(b.name));
            setClientsFiltredAndSortedAlphabetically(filteredClients);
        }
    }, [clients, clientNameFilter]);

    useEffect(() => {
        const sortedCagesByStatus = currentLocation?.cages.reduce(
            (acc, cage) => {
                if (cage.activeBioscopes?.length > 0 && cage.historical === null) {
                    acc.active.push(cage);
                } else if (cage.historical !== null && cage?.historical?.length > 0) {
                    acc.historical.push(cage);
                } else {
                    acc.inactive.push(cage);
                }
                return acc;
            },
            { active: [], inactive: [], historical: [] }
        );

        // Sort each type of cages by name
        if (sortedCagesByStatus) {
            sortedCagesByStatus.active.sort((a, b) => compareCageNames(a.name, b.name));
            sortedCagesByStatus.inactive.sort((a, b) => compareCageNames(a.name, b.name));
            sortedCagesByStatus.historical.sort((a, b) => compareCageNames(a.name, b.name));
        }

        const sortedLocationsByStatus = locations?.reduce(
            (acc, location) => {
                const hasActiveBioScopes = location.cages.some(
                    (cage) => cage.activeBioscopes?.length > 0
                );
                if (!hasActiveBioScopes) {
                    acc.inactive.push(location);
                } else {
                    acc.active.push(location);
                }
                return acc;
            },
            { active: [], inactive: [] }
        );

        setCagesSortedByStatus(sortedCagesByStatus);
        setLocationsSortedByStatus(sortedLocationsByStatus);
    }, [currentLocation]);

    useEffect(() => {
        if (locationId) {
            const location = locations.find((loc) => loc.id === locationId);
            setCurrentLocation(location ? location : null);

            if (location && cageId) {
                const cage = location.cages.find((cage) => cage.id === cageId);

                setCurrentCage(cage ? cage : null);
            } else {
                setCurrentCage(null);
            }
        } else {
            setCurrentLocation(null);
            setCurrentCage(null);
        }
    }, [client, locationId, cageId, locations]);

    const handleLocationDropDownItemClicked = (location: OverviewLocation) => {
        history.push(`/c/${client.id}/location/${location.id}`);
    };

    const handleClientDropDownItemClicked = (client: ClientInfo) => {
        setClientNameFilter('');
        history.push(`/c/${client.id}/`);
    };

    const renderClientDropdownItems = () => {
        const chunkSize = 20;
        const chunks = [];
        for (let i = 0; i < clientsFiltredAndSortedAlphabetically.length; i += chunkSize) {
            const chunk = clientsFiltredAndSortedAlphabetically.slice(i, i + chunkSize);
            chunks.push(chunk);
        }

        return (
            <>
                <legend className={styles.searchInput}>
                    <label>{placeholderText}</label>
                    <Input
                        placeholder={placeholderText}
                        type="text"
                        onChange={(e) => {
                            setClientNameFilter(e.target.value);
                        }}
                    />
                </legend>
                <div data-location="sorted">
                    <span>
                        {chunks.map((chunk, index) => (
                            <React.Fragment key={`chunk_index_${index}`}>
                                <DropdownItem divider />
                                {chunk.map((client) => (
                                    <DropdownItem
                                        key={`client_${client.id}`}
                                        onClick={() => handleClientDropDownItemClicked(client)}>
                                        {client.name}
                                    </DropdownItem>
                                ))}
                            </React.Fragment>
                        ))}
                    </span>
                </div>
            </>
        );
    };

    const renderLocationDropdownItems = () => {
        return (
            <>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div data-location="sorted">
                            <span>
                                <DropdownItem divider />
                                <DropdownItem header={true}>{t('Active locations')}</DropdownItem>
                                {locationsSortedByStatus?.active.map((location) => (
                                    <DropdownItem
                                        key={`location_${location.id}`}
                                        onClick={() => handleLocationDropDownItemClicked(location)}>
                                        {location.name}
                                    </DropdownItem>
                                ))}
                            </span>
                            {locationsSortedByStatus?.inactive.length > 0 && (
                                <span>
                                    <DropdownItem divider />
                                    <DropdownItem header={true}>
                                        {t('Inactive locations')}
                                    </DropdownItem>
                                    {locationsSortedByStatus?.inactive.map((location) => (
                                        <DropdownItem
                                            key={`location_inactive_${location.id}`}
                                            onClick={() =>
                                                handleLocationDropDownItemClicked(location)
                                            }>
                                            {location.name}
                                        </DropdownItem>
                                    ))}
                                </span>
                            )}
                        </div>
                    </>
                )}
            </>
        );
    };

    const handleCageDropDownItemClicked = (cage: OverviewCage) => {
        if (lastUrlPart) {
            history.push(
                `/c/${client.id}/location/${currentLocation.id}/pen/${cage.id}${lastUrlPart}`
            );
        } else {
            history.push(`/c/${client.id}/location/${currentLocation.id}/pen/${cage.id}`);
        }
    };

    const handleClientDropdowToggle = () => {
        setClientDropdownOpen((prev) => !prev);
        setClientNameFilter('');
    };

    const renderCageDropdownItems = () => {
        if (
            cagesSortedByStatus?.active.length === 0 &&
            cagesSortedByStatus?.inactive.length === 0
        ) {
            return <DropdownItem>{t('No cages available')}</DropdownItem>;
        }

        return (
            <>
                <span>
                    <DropdownItem header={true}>{t('Active cages')}</DropdownItem>
                    {cagesSortedByStatus?.active.map((cage) => (
                        <DropdownItem
                            key={`cage_${cage.id}`}
                            onClick={() => handleCageDropDownItemClicked(cage)}>
                            {cage.name}
                        </DropdownItem>
                    ))}
                </span>
                {cagesSortedByStatus?.inactive.length > 0 && (
                    <span>
                        <DropdownItem divider />
                        <DropdownItem header={true}>{t('Inactive cages')}</DropdownItem>
                        {cagesSortedByStatus?.inactive.map((cage) => (
                            <DropdownItem
                                key={`cage_inactive_${cage.id}`}
                                onClick={() => handleCageDropDownItemClicked(cage)}>
                                {cage.name}
                            </DropdownItem>
                        ))}
                    </span>
                )}
                {cagesSortedByStatus?.historical.length > 0 && (
                    <span>
                        <DropdownItem divider />
                        <DropdownItem header={true}>{t('Historical cages')}</DropdownItem>
                        {cagesSortedByStatus?.historical.map((cage) => (
                            <DropdownItem
                                key={`cage_historical_${cage.id}`}
                                onClick={() => handleCageDropDownItemClicked(cage)}>
                                {cage.name}
                            </DropdownItem>
                        ))}
                    </span>
                )}
            </>
        );
    };

    const renderLastPartOfUrl = (urlpath) => {
        if (lastPartToNiceNameMapper[urlpath]) {
            return (
                <React.Fragment>
                    <span data-type="locationCageSeparator">/</span>
                    <div className={styles.breadcrumbtokens}>
                        {t(lastPartToNiceNameMapper[urlpath])}
                    </div>
                </React.Fragment>
            );
        }
        const fragments: React.ReactNode[] = [];

        // if a path start with a hard mapper, pick it out, fix and remove from rest of url
        for (const lastPartToNiceNameMapperKey in lastPartToNiceNameMapper) {
            if (urlpath.startsWith(lastPartToNiceNameMapperKey)) {
                fragments.push(
                    <React.Fragment>
                        <span data-type="locationCageSeparator">/</span>
                        <div
                            key={`location_key_${lastPartToNiceNameMapperKey}`}
                            className={styles.breadcrumbtokens}>
                            {t(lastPartToNiceNameMapper[lastPartToNiceNameMapperKey])}
                        </div>
                    </React.Fragment>
                );
                urlpath = urlpath.slice(lastPartToNiceNameMapperKey.length);
                break;
            }
        }

        const urlSplit = urlpath
            .split('/')
            .filter((x) => x)
            .map((x) => x.replace('-', ' '))
            .map((x) => `${x[0].toUpperCase()}${x.slice(1)}`);
        urlSplit.map((urlToken, index) =>
            fragments.push(
                <React.Fragment key={`url_token_fragment_${index}`}>
                    <span data-type="locationCageSeparator">/</span>
                    <div key={`url_token_${index}`} className={styles.breadcrumbtokens}>
                        {t(urlToken)}
                    </div>
                </React.Fragment>
            )
        );
        return <React.Fragment>{fragments}</React.Fragment>;
    };

    return (
        <div className={styles.locationsAndCages}>
            {clients && (
                <React.Fragment>
                    {/*<span data-type="locationCageSeparator">/</span>*/}
                    {showHomeButton && (
                        <React.Fragment>
                            <div
                                className={styles.homeButton}
                                onClick={() => history.push(`/c/${client.id}`)}>
                                <HomeIcon width={20}></HomeIcon>
                            </div>

                            <span data-type="locationCageSeparator">/</span>
                        </React.Fragment>
                    )}

                    <UncontrolledDropdown
                        isOpen={clientDropdownOpen}
                        toggle={() => handleClientDropdowToggle()}>
                        <DropdownToggle className={styles.dropdownToggle} outline color="dark">
                            {client && client.name ? client.name : t('Choose client')}
                            <i className="pl-2 fa fa-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu
                            className={clientDropdownOpen ? styles.locations_dropdownMenu : ''}
                            direction="down">
                            {renderClientDropdownItems()}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </React.Fragment>
            )}

            {client && currentLocation && (
                <React.Fragment>
                    <span data-type="locationCageSeparator">/</span>
                    <UncontrolledDropdown
                        isOpen={locationDropdownOpen}
                        toggle={() => setLocationDropdownOpen((prev) => !prev)}>
                        <DropdownToggle className={styles.dropdownToggle} outline color="dark">
                            {currentLocation.name}
                            <i className="pl-2 fa fa-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu
                            className={locationDropdownOpen ? styles.locations_dropdownMenu : ''}
                            direction="down">
                            {renderLocationDropdownItems()}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </React.Fragment>
            )}

            {client && currentLocation && currentCage && (
                <React.Fragment>
                    <span data-type="locationCageSeparator">/</span>
                    <UncontrolledDropdown
                        direction="down"
                        isOpen={cageDropdownOpen}
                        toggle={() => setCageDropdownOpen((prev) => !prev)}>
                        <DropdownToggle className={styles.dropdownToggle} outline color="dark">
                            {currentCage.name}
                            <i className="pl-2 fa fa-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu className={cageDropdownOpen ? styles.cages_dropdownMenu : ''}>
                            {renderCageDropdownItems()}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </React.Fragment>
            )}
            {lastUrlPart && renderLastPartOfUrl(lastUrlPart)}
        </div>
    );
};

export default OptoBreadCrumb;
export { OptoBreadCrumb };
