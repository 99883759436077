import { useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { useIsPageAvailable } from 'hooks/is-page-available';
import { t } from 'i18next';
import { LaksvelRoutes } from 'routes/Client/Cage/pages/Laksvel/LaksvelRoutes';

import Page from '@/components/Layout/Page';
import { PageTitleForAnalytics } from '@/components/Layout/PageTitle/PageTitle';
import useCage, { CageProvider } from '@/contexts/cage-context';
import { LaksvelProvider } from '@/contexts/laksvel-context';
import { useMe } from '@/contexts/meContext';
import { DailyLaksvelResult } from '@/services/types';

import LaksvelPageContent from './LaksvelPageContent';

export interface LaksvelRouterPageProps extends RouteComponentProps {
    pageLoading?: boolean;
    pagedata?: DailyLaksvelResult[] | undefined;
}

const LaksvelContainerRouter = () => {
    const match = useRouteMatch();
    const { cageId, client, dayparts } = useCage();
    const { isOptoscaleAdmin, state } = useMe();

    const isPageAvailable = useIsPageAvailable();
    const availablePages = useMemo(() => {
        return (LaksvelRoutes ?? []).filter(
            (page) => (!isPageAvailable(page) ? false : true) && page.hidden !== true
        );
    }, [isPageAvailable]);

    return (
        <CageProvider cageId={cageId}>
            <LaksvelProvider cageId={cageId} dayparts={dayparts}>
                <Switch>
                    {availablePages.map((page) => (
                        <Route
                            key={page.key}
                            exact={true}
                            path={`${match.url}${page?.path ?? ''}`}
                            render={(props: RouteComponentProps) => {
                                return (
                                    <>
                                        <PageTitleForAnalytics
                                            title={`Cage - Laksvel - ${page.label}`}
                                            category={'laksvel'}
                                        />
                                        <Page title={page.label || 'Untitled'}>
                                            <LaksvelPageContent
                                                laksvelmenu={availablePages}
                                                clientId={client?.id}
                                                cageId={cageId}
                                                isOptoscaleAdmin={isOptoscaleAdmin}
                                                title={
                                                    page?.params?.i18n
                                                        ? t(page.params.i18n, {
                                                              postProcess: 'capitalizeFirstLetter',
                                                          })
                                                        : ''
                                                }
                                                routerProps={props}>
                                                {page?.page ? <page.page /> : null}
                                            </LaksvelPageContent>
                                        </Page>
                                    </>
                                );
                            }}
                        />
                    ))}
                </Switch>
            </LaksvelProvider>
        </CageProvider>
    );
};

export default LaksvelContainerRouter;
