import React from 'react';
import { useTranslation } from 'react-i18next';

import { DailyLaksvelResult } from '@/services/types';

import styles from './ScoreTable.module.scss';

type ScoreTableData = {
    headers: string[];
    rows: string[][];
};

export interface ScoreTableProps {
    children?: React.ReactNode;
    data: ScoreTableData;
}

const ScoreTable = ({ data }: ScoreTableProps) => {
    const { t } = useTranslation();
    return (
        <div className={styles.scoretable}>
            <table>
                <thead>
                    <tr>
                        {data.headers.map((header, index) => (
                            <th key={index}>
                                <span>{t(header)}</span>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>
                                    <span>
                                        {isNaN(Number(cell))
                                            ? t(cell, { postProcess: 'capitalizeFirstLetter' })
                                            : `${Number(cell).toFixed(1)}%`}
                                    </span>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

type Score = {
    percent: number;
    raw: number;
} | null;

const laksvelToTabularData = (data: DailyLaksvelResult[]) => {
    if (!data || data.length === 0) return { headers: [], rows: [] };

    const currentDay = data[0];
    const result = Object.entries(currentDay)
        .filter(([key]) => key !== 'day')
        .map(([key, value]: [string, Record<string, Score>]) => {
            const filteredValues = Object.entries(value)
                .filter(([key]) => key !== 'score_0' && key !== 'sum_score1_score2_score3_percent')
                .map(([, score]) => score?.percent ?? 0);

            const total = filteredValues.reduce((acc, percent) => acc + percent, 0).toFixed(1);

            return [key, ...filteredValues, total];
        });

    const tableStructure = {
        headers: ['Welfare parameters', 'Laksvel 1', 'Laksvel 2', 'Laksvel 3', 'Total'],
        rows: result.map((row) =>
            row.map((cell) => (cell === 0 || cell === '0.0' ? '-' : cell.toString()))
        ),
    };

    return tableStructure;
};

export default ScoreTable;
export { ScoreTable, laksvelToTabularData };
