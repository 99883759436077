import type React from 'react';
import type { MouseEvent } from 'react';

import SearchIcon from 'components/atoms/icons/SearchIcon';

import IconButton, { IconButtonProps } from '../IconButton/IconButton';

export interface ResetZoomProps extends IconButtonProps, React.HTMLAttributes<HTMLButtonElement> {
    onClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
}

const ResetZoom: React.FC<ResetZoomProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    onClick,
    variant = 'secondary',
}: ResetZoomProps) => {
    return (
        <IconButton name="zoomReset" variant={variant} onClick={onClick} onlyIcon={true}>
            <SearchIcon size={24} />
        </IconButton>
    );
};

export default ResetZoom;
export { ResetZoom };
