import React from 'react';

import styles from './GenericSidebarItemIcon.module.scss';

export interface GenericSidebarItemIconProps {
    children?: React.ReactNode;
    customClassName?: string;
}

const GenericSidebarItemIcon = ({
    children,
    customClassName = '',
}: GenericSidebarItemIconProps) => {
    return <div className={`${styles.genericsidebaritemicon} ${customClassName}`}>{children}</div>;
};

export default GenericSidebarItemIcon;
export { GenericSidebarItemIcon };
