import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { useResizeDetector } from 'react-resize-detector';
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Legend,
    PluginChartOptions,
    Title,
    Tooltip,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import cn from 'classnames';
import { saveAs } from 'file-saver';

import { SkeletonLoader } from 'components/atoms/SkeletonLoader';
import { ChartHeaderBar } from 'components/molecules/ChartHeaderBar/ChartHeaderBar';

import { ChartActionButtons } from '../OptoMixedChart/OptoMixedChart';
import htmlLegendPlugin from '../plugins/htmlLegendPlugin';

import styles from './OptoBarChart.module.scss';

ChartJS.register(Legend, htmlLegendPlugin, Tooltip, Title, zoomPlugin);

export type OptoBarChartProps = {
    chartName: string;
    graphType?: 'bar';
    chartData: ChartData<'bar'>;
    chartOptions?: (data: ChartData<'bar'>) => ChartOptions<'bar'>;
    pluginOptions?: PluginChartOptions<'bar'>;
    loading?: boolean;
    actionButtons?: ChartActionButtons;
    customTimeSwitchHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

ChartJS.register(Legend, htmlLegendPlugin, zoomPlugin, CategoryScale, BarController, BarElement);

const OptoBarChart = ({
    chartName = '',
    chartData,
    chartOptions,
    loading = false,
    actionButtons = {
        copyAsPng: true,
        resetZoom: false,
        downloadAsPng: true,
        fullScreen: true,
        timeScaleSwitch: false,
        timeSwitchWeekly: false,
    },
    customTimeSwitchHandler = () => {},
}: OptoBarChartProps) => {
    const chartRef = useRef<ChartJS<'bar'>>(null);
    const chartBoundaries = useRef<HTMLDivElement>(null);

    const [chart, setChart] = useState<ChartJS<'bar'> | null>(null);
    const [isFullScreen, setisFullscreen] = useState(false);
    const [topheading, setTopheading] = useState(false);

    // Use useMemo to compute options when chartData changes
    const barOptions = useMemo(() => {
        return chartOptions ? chartOptions(chartData) : {};
    }, [chartOptions, chartData]);

    const { width } = useResizeDetector({
        handleWidth: true,
        targetRef: chartBoundaries,
        onResize: () => {
            if (width && width > 600 && !topheading) {
                setTopheading(true);
                if (barOptions.plugins?.title) {
                    barOptions.plugins.title.display = false;
                }
                chartRef?.current?.update();
                chartRef?.current?.resize();
            } else if (width && width < 600 && topheading) {
                setTopheading(false);
                if (barOptions.plugins?.title) {
                    barOptions.plugins.title.display = true;
                }
                chartRef?.current?.update();
                chartRef?.current?.resize();
            }
        },
    });

    const showAsFullScreen = () => {
        if (chartBoundaries.current) {
            isFullScreen ? document.exitFullscreen() : chartBoundaries.current.requestFullscreen();
            chartBoundaries.current.onfullscreenchange = () => {
                chartRef?.current?.resize();
                setisFullscreen(!isFullScreen);
            };
            return;
        }
    };

    const downloadAsPngHandler = () => {
        const dateString = new Date().toISOString().split('T')[0];
        chart?.canvas.toBlob((blob) =>
            saveAs(blob, chartName.length > 0 ? `${chartName}-${dateString}.png` : 'chart.png')
        );
    };

    const copyAsPngHandler = async (ev) => {
        if (!chart) {
            console.warn('No chart to copy');
            return;
        }
        const response = await chart.canvas.toBlob((blob) =>
            navigator.clipboard.write([new window.ClipboardItem({ 'image/png': blob })])
        );
        // TODO - show some kind of notification
        console.log('copied to clipboard', response);
    };

    const resetZoomHandler = () => {
        chart?.resetZoom();
    };

    /**
     * Modify chart options on mount
     */
    useEffect(() => {
        if (chartRef.current) {
            // Update chart instance reference
            setChart(chartRef.current);

            // Update chart options
            chartRef.current.options = barOptions;
            chartRef.current.update();
        }
    }, [barOptions, chartData]);

    return (
        <SkeletonLoader loading={loading} name={`${chartName} loading..`}>
            <div className={styles.optobarchart} ref={chartBoundaries}>
                <div className={styles.chartHeaderSection}>
                    <div className={styles.chartTitle}>
                        {topheading && barOptions?.plugins?.title && (
                            <h2>{barOptions.plugins.title.text}</h2>
                        )}
                    </div>
                    <div
                        data-title={chartName}
                        className={cn(styles.htmlLegendBar, 'legend-container')}
                    />
                    {chart && (
                        <ChartHeaderBar
                            chart={chart}
                            fullScreenHandler={showAsFullScreen}
                            downloadAsPngHandler={downloadAsPngHandler}
                            copyAsPngHandler={copyAsPngHandler}
                            resetZoomHandler={resetZoomHandler}
                            switchButtonsHandler={customTimeSwitchHandler}
                            visibleButtons={actionButtons}
                            isFullScreen={isFullScreen}
                        />
                    )}
                </div>
                <div className={cn(styles.tooltipContainer, 'tooltip-container')} />
                <Bar
                    data={chartData}
                    aria-description="bioscope chart data"
                    aria-label="bioscope chart data"
                    plugins={[htmlLegendPlugin]}
                    options={barOptions}
                    ref={chartRef}
                />
            </div>
        </SkeletonLoader>
    );
};

export default OptoBarChart;
export { OptoBarChart };
