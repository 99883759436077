import type React from 'react';
import { type MouseEvent, type ReactNode, useState } from 'react';
import type { Chart } from 'chart.js';
import classNames from 'classnames';

import { IconButton } from '@/components/atoms/Buttons/IconButton';
import { CopyIcon } from 'components/atoms/icons';

import { IconButtonProps } from '../IconButton/IconButton';

import styles from './CopyAsPng.module.scss';

export interface CopyAsPngProps extends IconButtonProps {
    children?: ReactNode;
    chart?: Chart<'line' | 'bar' | 'doughnut' | 'pie' | 'polarArea'>;
    className?: string;
    onClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
}

const CopyAsPng: React.FC<CopyAsPngProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    chart,
    className = '',
    onClick = (e) => {},
    variant = 'secondary',
}: CopyAsPngProps) => {
    const [isCopied, setIsCopied] = useState(false);

    return (
        <div className={classNames(styles.copyaspng, className)}>
            {/*  <span className={styles.copied}>{isCopied ? 'Copied!' : null}</span> */}
            <IconButton onClick={onClick} onlyIcon={true} variant={variant}>
                <CopyIcon size={24} />
            </IconButton>
        </div>
    );
};

export default CopyAsPng;
export { CopyAsPng };
