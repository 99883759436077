import React from 'react';

import styles from './CompareIcon.module.scss';

export interface CompareIconProps {
    children?: React.ReactNode;
    height?: number;
    color?: string;
    size?: 'small' | 'medium' | 'large';
}

const CompareIcon = ({ height = 24, color = 'white', size = 'medium' }: CompareIconProps) => {
    const widthValue = size === 'small' ? 16 : size === 'medium' ? 24 : 32;

    return (
        <div className={styles.compareicon}>
            <svg
                height={widthValue}
                width={widthValue}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none">
                <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z" />
                <path
                    fill={color}
                    d="M20 14a1.5 1.5 0 0 1 .144 2.993L20 17H7.621l1.44 1.44a1.5 1.5 0 0 1-2.008 2.224l-.114-.103-3.829-3.83c-.974-.974-.34-2.617.991-2.725l.14-.006H20ZM14.94 3.44a1.5 1.5 0 0 1 2.007-.104l.114.103 3.829 3.83c.974.974.34 2.617-.991 2.725l-.14.006H4a1.5 1.5 0 0 1-.144-2.993L4 7h12.379l-1.44-1.44a1.5 1.5 0 0 1 0-2.12Z"
                />
            </svg>
        </div>
    );
};

export default CompareIcon;
export { CompareIcon };
