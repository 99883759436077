import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { DailyLaksvelResult, Last500fishResult, LaksvelTrend } from 'services/types';

import { placeHolder } from '@/services/config';
import {
    useLaksvelController_GetLaksvelLast500Fish,
    useLaksvelController_laksvelDaily,
    useWelfareController_allImages,
} from '@/services/hooks';

interface LaksvelContextType {
    laksvelData: DailyLaksvelResult[];
    laksvelTrendData: LaksvelTrend;
    averageLaksvelData: Last500fishResult;
    cageId: number;
    dayparts:
        | {
              id: number;
              tag: string;
          }[]
        | undefined;
    client?:
        | {
              id: number;
              name: string;
          }
        | undefined;
    loading: boolean;
    footNote: string;
}

const LaksvelContext = createContext<LaksvelContextType | null>(null);

const LaksvelProvider = ({
    cageId,
    dayparts,
    children,
}: {
    cageId: number | undefined;
    dayparts: { tag: string; id: number }[] | undefined;
    children: ReactNode;
}) => {
    const match = useRouteMatch();
    const usePara = useParams<{ cageId: string }>();

    // Fetch data from the server
    if (!cageId) {
        return null;
    }

    const { data: laksvelMetaData, isLoading } = useLaksvelController_laksvelDaily(cageId, {}, {});

    const { data: laksvel500MetaData, isLoading: is500Loading } =
        useLaksvelController_GetLaksvelLast500Fish(
            cageId,
            {
                // daypartTag: (dayparts?.[1].tag || 'day').toString(),
                //placeholderData: placeHolder([]),
                //keepPreviousData: true,
            },
            {}
        );

    const {
        data: lakesvelImageData,
        isLoading: imagesdataLoading,
        isFetching: imageDataFetching,
    } = useWelfareController_allImages(
        cageId,
        { type: 'color-welfare-image' },
        { placeholderData: placeHolder([]), keepPreviousData: true }
    );

    const laksvelRestContext = useMemo<LaksvelContextType>(() => {
        const laksvelData = laksvelMetaData?.data?.dailyData ?? ([] as DailyLaksvelResult[]);
        const laksvelTrendData =
            laksvelMetaData?.data?.trend ?? (undefined as unknown as LaksvelTrend);
        const imagesdata = lakesvelImageData?.data ?? [];
        const averageLaksvelData = laksvel500MetaData?.data ?? ({} as Last500fishResult);

        const dataStillLoading = isLoading || imagesdataLoading || is500Loading;

        if (!laksvelData || !imagesdata || !averageLaksvelData) {
            const defaultObject = {
                loading: dataStillLoading,
                laksvelData: [] as DailyLaksvelResult[],
                averageLaksvelData: {} as Last500fishResult,
                laksvelTrendData: undefined as unknown as LaksvelTrend,
                laksvelImages: [],
                cageId: cageId,
                dayparts: dayparts,
                footNote: '',
            };

            return defaultObject;
        }

        const populatedObject = {
            loading: dataStillLoading,
            laksvelData: laksvelData,
            laksvelTrendData: laksvelTrendData,
            averageLaksvelData: averageLaksvelData,
            laksvelImages: imagesdata,
            cageId,
            dayparts,
            footNote: '',
            // footNote: `The dd graph displays laksvel scores from ${moment(
            //     averageLaksvelData.day
            // ).fromNow()} where the total count is ${averageLaksvelData.fishCount} fish .`,
        };

        return populatedObject;
    }, [
        cageId,
        dayparts,
        isLoading,
        imagesdataLoading,
        is500Loading,
        laksvelMetaData,
        lakesvelImageData,
        laksvel500MetaData,
    ]);

    return <LaksvelContext.Provider value={laksvelRestContext}>{children}</LaksvelContext.Provider>;
};

const useLaksvel = (): LaksvelContextType => {
    return useContext(LaksvelContext) as LaksvelContextType;
};

export default useLaksvel;
export { LaksvelProvider, useLaksvel, LaksvelContext };
