import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert } from 'reactstrap';
import moment from 'moment';
import { OverviewLocation } from 'services/types';

import { AmountRatioWithText } from '@/components/atoms/AmountRatioWithText';
import { IconButton } from '@/components/atoms/Buttons/IconButton';
import { LiceIcon } from '@/components/atoms/icons/LiceIcon';
import { NavigateToIcon } from '@/components/atoms/icons/NavigateToIcon';
import Notice from '@/components/atoms/Notice/Notice';
import UmerBox from '@/components/atoms/UmerBox';
import AverageHeader from '@/components/molecules/AverageHeader';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { RequestError } from '@/services/config';
import { Button } from 'components/atoms/Buttons';
import { DownloadCsvXlsx } from 'components/DownloadCsvXlsx';

import { Location } from './Location/Location';

import styles from './overview.module.scss';

export const hasActiveBioscope = (location) =>
    location?.cages?.some((cage) => cage?.activeBioscopes?.length > 0);

export const amountOfActiveBioscopes = (locations) => {
    let amount = 0;
    locations.forEach((location) => {
        location?.cages?.forEach((cage) => {
            if (cage?.weight?.day !== undefined) {
                const lastMeasurement = new Date(
                    Date.parse(moment(cage?.weight?.day).format('YYYY-MM-DDTHH:mm:ss.sssZ'))
                );

                const now = new Date();

                const diff =
                    now.getFullYear() === lastMeasurement.getFullYear() &&
                    now.getMonth() === lastMeasurement.getMonth() &&
                    now.getDate() === lastMeasurement.getDate();

                if (diff) {
                    amount++;
                }
            }
        });
    });

    return amount;
};

export const totalAmountOfActiveBioscopes = (locations) => {
    let amount = 0;

    locations.forEach((location) => {
        location?.cages?.forEach((cage) => {
            amount += cage?.activeBioscopes.length;
        });
    });

    return amount;
};

const DownloadData = ({ locations }: { locations: OverviewLocation[] }) => {
    const { t } = useTranslation();
    const allData = useMemo(
        () =>
            locations?.flatMap((el) => {
                return el.cages.flatMap((x) => {
                    const dataMin = {
                        LocationName: el?.name,
                        PenName: x?.name,
                        LastMeasDateForWeight: moment(x?.weight?.day).format('YYYY-MM-DD') ?? '',
                        Weight: x?.weight?.livingWeight
                            ? `${x?.weight?.livingWeight?.toFixed(2)} g`
                            : '',
                        Growth: x?.weight?.growth ? `${x?.weight?.growth.toFixed(2)} g/day` : '',
                        BioscopeStatus: x?.weight?.measStatusList[0]
                            ? t(`measurementStatusTexts.${x?.weight?.measStatusList[0]}`)
                            : t('All good'),
                        ActiveBioscope: x?.activeBioscopes[0] ?? '',
                    };
                    const dataWithFishHealth = {
                        LastMeasDateForWounds: moment(x?.welfare?.day).format('YYYY-MM-DD') ?? '',
                        Wounds: x?.welfare?.woundRate?.toFixed(2) ?? '',
                    };
                    const dataWithLice = {
                        LastMeasDateForLice: moment(x?.lice?.day).format('YYYY-MM-DD') ?? '',
                        FemaleLice: x?.lice?.femaleLegacyAvg?.toFixed(2) ?? '',
                    };
                    return {
                        ...dataMin,
                        ...dataWithFishHealth,
                        ...dataWithLice,
                    };
                });
            }) ?? [],
        [locations]
    );

    const dataColumnNames = allData.length > 0 ? Object.keys(allData[0]) : [];
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    return (
        <>
            <DownloadCsvXlsx
                label="CSV"
                data={allData}
                nameToInclude={'SiteOverview'}
                COLUMNS={formattedDataColumnNames}
            />
            <DownloadCsvXlsx
                label="XLSX"
                data={allData}
                nameToInclude={'SiteOverview'}
                COLUMNS={formattedDataColumnNames}
            />
        </>
    );
};

export interface SortedLocationsProps {
    locations: OverviewLocation[];
    status: any;
    clientId: number;
    hasAccessToMarkHistorical: boolean;
    markHistorical: (cageId: number, historical: boolean) => Promise<unknown>;
    isOptoscaleAdmin?: boolean;
    renderLazy?: boolean;
}

const SortedLocations = ({
    locations,
    hasAccessToMarkHistorical,
    status,
    clientId,
    markHistorical,
    isOptoscaleAdmin = false,
    renderLazy = false,
}: SortedLocationsProps) => {
    if (!locations.length) {
        return null;
    }
    const history = useHistory();
    const { t } = useTranslation();

    const { pathname } = useLocation();

    const createCompareUrl = (cageIds: number[]) => {
        const cagesToCompare = locations
            .flatMap((l) => l.cages)
            .filter((c) => cageIds.includes(c.id));
        const queryString = cagesToCompare
            .map((item) => `${encodeURIComponent(item.name)}~${encodeURIComponent(item.id)}`)
            .join(',');
        return cagesToCompare.length > 1
            ? `/c/${clientId}/compare?data=${queryString}&daypartTag=day`
            : '#';
    };

    return (
        <>
            {locations.map((location) => {
                return (
                    <UmerBox key={location.id} id={location.name.replace(/\s+/g, '')}>
                        {renderLazy ? (
                            <>
                                <div
                                    onClick={() => {
                                        history.push(`/c/${clientId}/location/${location.id}`);
                                    }}
                                    className={`${styles.header} `}
                                    data-species={location.fishTypes.name.toLowerCase()}>
                                    <div className={styles.leftSideHeaderContainer}>
                                        <h2 className={styles.locationName}>{location.name}</h2>

                                        <div className={styles.fishNameContainer}>
                                            {t(location.fishTypes?.name)}
                                        </div>
                                    </div>

                                    <div>
                                        <Button
                                            size={'medium'}
                                            color={'secondary'}
                                            className={styles.navigateToButton}
                                            onClick={() =>
                                                history.push(
                                                    `/c/${clientId}/location/${location.id}`
                                                )
                                            }>
                                            {t('Go to site')}
                                            <NavigateToIcon
                                                width={15}
                                                height={20}
                                                color={'white'}
                                            />
                                        </Button>
                                    </div>
                                </div>
                                <div className={styles.activeinactivebioscopes}>
                                    {isOptoscaleAdmin && (
                                        <AmountRatioWithText
                                            leftSideAmount={amountOfActiveBioscopes([location])}
                                            rightSideAmount={totalAmountOfActiveBioscopes([
                                                location,
                                            ])}
                                            text={'bioscopes measuring'}
                                        />
                                    )}
                                </div>
                                <AverageHeader location={location} />
                                <hr />
                                <Location
                                    key={location.id}
                                    location={location}
                                    createCompareUrl={createCompareUrl}
                                    hasAccessToMarkHistorical={hasAccessToMarkHistorical}
                                    markHistorical={markHistorical}
                                    hideCompare={true}
                                    hideMarkHistorical={true}
                                    clientId={clientId}
                                    hideNoMeasurements={true}
                                    showLocationNavigationButton={false}
                                    hideInactive={true}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </UmerBox>
                );
            })}
        </>
    );
};

interface OverviewProps {
    clientId: number;
    locations: OverviewLocation[];
    hasAccessToMarkHistorical: boolean;
    markHistorical: (cageId: number, historical: boolean) => Promise<unknown>;
    isOptoscaleAdmin?: boolean;
    containerIsLoading: boolean;
    containerError: Error | RequestError | null;
    clientName?: string | null;
}

const Overview = ({
    clientId,
    locations,
    markHistorical,
    hasAccessToMarkHistorical,
    isOptoscaleAdmin = false,
    containerIsLoading = false,
    containerError,
    clientName = '',
}: OverviewProps) => {
    const { t } = useTranslation();

    //The block below ensures we scroll to the correct location when the page is loaded
    //It is a workaround for the fact that the page is rendered before the data is loaded
    //and therefore the page does not know where to scroll to
    //The block below is based on https://ericdcobb.medium.com/scrolling-to-an-anchor-in-react-when-your-elements-are-rendered-asynchronously-8c64f77b5f34
    const { hash, pathname } = useLocation();
    const history = useHistory();
    const [showInactive, setShowInactive] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);

    {
        const scrolledRef = React.useRef(false);

        React.useEffect(() => {
            if (hash && !scrolledRef.current) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    scrolledRef.current = true;
                }
            }
        });
    }

    const dropDownIsOpenHandler = (open: boolean) => {
        if (open) {
            setDropDownOpen(open);
            setShowInactive(true);
        }
    };

    const hasAccessToLice = locations.some((location) => location.cages.some((cage) => cage.lice));

    locations.sort((a, b) => a.name.localeCompare(b.name));
    const activeLocations = useMemo(
        () => locations.filter((location) => hasActiveBioscope(location)),
        [locations]
    );

    function maxDate(cages: { weight?: { day: string } }[]) {
        const candidateCages = cages.filter((cage) => cage.weight?.day);
        const max = Math.max(...candidateCages.map((cage) => new Date(cage.weight.day).valueOf()));
        return isNaN(max) ? -1 : max;
    }

    const inactiveLocations = useMemo(
        () =>
            locations
                .filter((location) => !hasActiveBioscope(location))
                .sort((a, b) => maxDate(a.cages) - maxDate(b.cages))
                .reverse(),
        [locations]
    );

    if (!locations) {
        return <Alert color="danger">{t('No locations')}</Alert>;
    }

    const dropdownLocations = locations.map((location) => {
        return {
            name: location.name,
            href: `#${location.name.replace(/\s+/g, '')}`,
            isActive: hasActiveBioscope(location),
        };
    });

    const { ref, inView } = useInView({
        /* Optional options */
        initialInView: false,
        triggerOnce: true,
        rootMargin: '100px',
        onChange: (inView, entry) => {
            if (inView || dropDownOpen) {
                setShowInactive(true);
            }
        },
    });
    const showNotice = useMemo(() => {
        const currentDate = moment();
        const removalDate = moment('2024-06-24T08:00:00'); // Target date and time to remove the notice
        return currentDate.isBefore(removalDate);
    }, []);

    return (
        <div>
            {showNotice && (
                <Notice
                    heading={t('NoticeHeading')}
                    message={t('NoticeMessage')}
                    variant="default"
                />
            )}
            <div className={styles.siteOverviewPage}>
                <PageTitleRow
                    title={clientName ? clientName : ''}
                    subTitle={t('Region overview')}
                    importantMessage={
                        clientId === 59 ? (
                            <p>
                                <i className="fa fa-info-circle" />
                                {t('Info text for Chile')}
                            </p>
                        ) : null
                    }>
                    <div className={styles.pageTitleRowButtonContainer}>
                        {hasAccessToLice ? (
                            <IconButton
                                size={'medium'}
                                variant={'secondary'}
                                onClick={() => history.push(`/c/${clientId}/lice-report`)}
                                buttonText={t('Lice report')}>
                                <LiceIcon height={20} color={'black'} />
                            </IconButton>
                        ) : (
                            <></>
                        )}
                        <DownloadData locations={locations} />
                    </div>
                </PageTitleRow>

                <div className={styles.activeinactivebioscopes}>
                    {isOptoscaleAdmin && (
                        <AmountRatioWithText
                            leftSideAmount={amountOfActiveBioscopes(locations)}
                            rightSideAmount={totalAmountOfActiveBioscopes(locations)}
                            text={'bioscopes measuring'}
                        />
                    )}
                </div>

                <SortedLocations
                    renderLazy={true}
                    locations={activeLocations}
                    status={'active'}
                    clientId={clientId}
                    hasAccessToMarkHistorical={hasAccessToMarkHistorical}
                    markHistorical={markHistorical}
                    isOptoscaleAdmin={isOptoscaleAdmin}
                />
                <div ref={ref}>
                    <hr className={styles.divider} data-label={t('Inactive locations')} />
                    <SortedLocations
                        renderLazy={showInactive}
                        locations={inactiveLocations}
                        status={'inActive'}
                        clientId={clientId}
                        hasAccessToMarkHistorical={hasAccessToMarkHistorical}
                        markHistorical={markHistorical}
                    />
                </div>
            </div>
        </div>
    );
};

export default Overview;
