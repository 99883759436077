import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { UmerBox } from '@/components/atoms/UmerBox';
import { OptoBarChart } from '@/components/organisms/OptoGraphs';
import Options, {
    categoryScale,
    chartTitle,
    minMax,
    formatAxsis,
    autoTimeScale,
    weightAndLengthUnitConverter,
    TypeNames,
    startYAxisAtZero,
    noDots,
} from '@/components/organisms/OptoGraphs/optionsModifiers';
import {
    defaultBarChartOptions,
    last500LaksvelToBarChartMapper,
} from '@/components/organisms/OptoGraphs/OptoBarChart';
import OptoMixedChart, {
    defaultLineChartOptions,
} from '@/components/organisms/OptoGraphs/OptoMixedChart';
import { DailyLaksvelMapper } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChartMappers';
import { useLaksvel } from '@/contexts/laksvel-context';

import { LaksvelRouterPageProps } from '../LaksvelContainerRouter';

import styles from '../Laksvel.module.scss';

const LaksvelMaturation: FunctionComponent<LaksvelRouterPageProps> = () => {
    const { t } = useTranslation();

    const { loading, laksvelData, averageLaksvelData } = useLaksvel();

    const last500Mapped = last500LaksvelToBarChartMapper({
        data: averageLaksvelData,
        inactiveSetLegend: [],
        property: 'maturity',
    });

    return (
        <>
            <div className={styles.laksvelPageContentGrid_50_100}>
                <UmerBox>
                    <OptoBarChart
                        chartData={last500Mapped}
                        chartName={t('Status')}
                        loading={loading}
                        chartOptions={Options(defaultBarChartOptions).modify(
                            chartTitle({ title: t('Status') }),
                            categoryScale({
                                labels: last500Mapped.labels as string[] | undefined,
                            }),
                            minMax({ min: 0, max: 100 }),
                            formatAxsis({
                                axsisID: 'y',
                                tickType: 'percent',
                                descimals: 0,
                            })
                        )}
                    />
                </UmerBox>
                <UmerBox>
                    <OptoMixedChart
                        chartName={t('Development')}
                        chartData={DailyLaksvelMapper({
                            data: loading ? [] : laksvelData,
                            laksvelAttribute: 'maturity',
                        })}
                        actionButtons={{
                            copyAsPng: true,
                            resetZoom: true,
                            downloadAsPng: true,
                            fullScreen: true,
                            timeScaleSwitch: false,
                        }}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            chartTitle({ title: t('Development') }),
                            autoTimeScale({}),
                            weightAndLengthUnitConverter({
                                type: TypeNames.percent,
                                fromUnit: '%',
                                toUnit: '%',
                            }),
                            startYAxisAtZero({}),
                            noDots(),
                            minMax({ min: 0, max: 100 }),
                            formatAxsis({
                                axsisID: 'y',
                                tickType: TypeNames.percent,
                                descimals: 0,
                            })
                        )}
                        loading={loading}
                    />
                </UmerBox>
                <UmerBox>Detection</UmerBox>
            </div>
        </>
    );
};

export default LaksvelMaturation;
export { LaksvelMaturation };
