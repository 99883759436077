import helpIcon from '@/components/atoms/icons/HelpIcon';
import homeIcon from '@/components/atoms/icons/HomeIcon';
import liceIcon from '@/components/atoms/icons/LiceIcon';
import moveIcon from '@/components/atoms/icons/MoveIcon';
import userIcon from '@/components/atoms/icons/UserIcon';
import { Pages, SidebarType } from '@/components/molecules/GenericSidebar';
import { SidebarPageOptionObject } from '@/components/molecules/GenericSidebar/SidebarPageOptionObjects';

const pages: Pages = [
    {
        id: 1,
        exact: true,
        path: '',
        label: 'Region overview',
        sidebarToShowFor: SidebarType.regionLevel,
        image: homeIcon,
    },

    {
        id: 2,
        path: '/lice-report',
        label: 'Lice report',
        sidebarToShowFor: SidebarType.regionLevel,
        exact: true,
        image: liceIcon,
    },

    {
        id: 3,
        path: '/users',
        label: 'Users',
        sidebarToShowFor: SidebarType.regionLevel,
        exact: true,
        image: userIcon,
    },

    {
        id: 4,
        path: '/move-bioscope',
        label: 'Move bioscope',
        sidebarToShowFor: SidebarType.regionLevel,
        exact: true,
        image: moveIcon,
    },
    {
        id: 5,
        path: '/help',
        label: 'Help',
        sidebarToShowFor: SidebarType.regionLevel,
        exact: true,
        image: helpIcon,
    },
].map((page, index) => ({ key: index, ...page }));

const regionSidebarOptions: SidebarPageOptionObject[] = pages.map((item) => {
    return {
        page: item,
        url: '',
        active: false,
    };
});

export default regionSidebarOptions;
