import type React from 'react';
import type { MouseEvent, ReactNode } from 'react';
import type { Chart } from 'chart.js';

import { IconButton } from '@/components/atoms/Buttons/IconButton';
import DownloadIcon from 'components/atoms/icons/DownloadIcon';

import { IconButtonProps } from '../IconButton/IconButton';

export interface DownloadAsPngProps extends IconButtonProps {
    children?: ReactNode;
    chart?: Chart<'line' | 'bar' | 'doughnut' | 'pie' | 'polarArea'>;
    className?: string;
    onClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
}

const DownloadAsPng: React.FC<DownloadAsPngProps & React.ButtonHTMLAttributes<HTMLButtonElement>> =
    ({ onClick = (e) => {}, variant = 'secondary' }: DownloadAsPngProps) => {
        return (
            <IconButton onClick={onClick} onlyIcon={true} variant={variant}>
                <DownloadIcon size={24} />
            </IconButton>
        );
    };

export default DownloadAsPng;
export { DownloadAsPng };
