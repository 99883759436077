import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import BlockTitle from '@/components/atoms/BlockTitle';
import { UmerBox } from '@/components/atoms/UmerBox';
import FishHealthFilter from '@/components/molecules/FishHealthFilter';
import { ImageViewContainer } from '@/components/organisms/ImageViewer';
import {
    autoTimeScale,
    categoryScale,
    chartTitle,
    minMax,
    formatAxsis,
    weightAndLengthUnitConverter,
    TypeNames,
    startYAxisAtZero,
    noDots,
    Options,
} from '@/components/organisms/OptoGraphs/optionsModifiers';
import OptoBarChart, {
    defaultBarChartOptions,
    last500LaksvelToBarChartMapper,
} from '@/components/organisms/OptoGraphs/OptoBarChart';
import {
    defaultLineChartOptions,
    OptoMixedChart,
} from '@/components/organisms/OptoGraphs/OptoMixedChart';
import { DailyLaksvelMapper } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChartMappers';
import { useLaksvel } from '@/contexts/laksvel-context';

import { LaksvelRouterPageProps } from '../LaksvelContainerRouter';

import styles from '../Laksvel.module.scss';

const Emaciation: FunctionComponent<LaksvelRouterPageProps> = () => {
    const { t } = useTranslation();
    const { loading, laksvelData, averageLaksvelData } = useLaksvel();

    const last500Mapped = last500LaksvelToBarChartMapper({
        data: averageLaksvelData,
        inactiveSetLegend: [],
        property: 'emaciation',
    });

    return (
        <>
            <div className={styles.laksvelPageContentGrid_50_100}>
                <UmerBox>
                    <OptoBarChart
                        chartData={last500Mapped}
                        chartName={t('Status')}
                        loading={loading}
                        chartOptions={Options(defaultBarChartOptions).modify(
                            chartTitle({ title: t('Status') }),
                            categoryScale({
                                labels: last500Mapped.labels as string[] | undefined,
                            }),
                            minMax({ min: 0, max: 100 }),
                            formatAxsis({
                                axsisID: 'y',
                                tickType: 'percent',
                                descimals: 0,
                            })
                        )}
                    />
                </UmerBox>
                <UmerBox>
                    <OptoMixedChart
                        chartName={t('Development')}
                        chartData={DailyLaksvelMapper({
                            data: loading ? [] : laksvelData,
                            laksvelAttribute: 'emaciation',
                        })}
                        actionButtons={{
                            copyAsPng: true,
                            resetZoom: true,
                            downloadAsPng: true,
                            fullScreen: true,
                            timeScaleSwitch: false,
                        }}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            autoTimeScale({}),
                            chartTitle({ title: t('Development') }),
                            weightAndLengthUnitConverter({
                                type: TypeNames.percent,
                                fromUnit: '%',
                                toUnit: '%',
                            }),
                            startYAxisAtZero({}),
                            noDots(),
                            minMax({ min: 0, max: 100 }),
                            formatAxsis({
                                axsisID: 'y',
                                tickType: TypeNames.percent,
                                descimals: 0,
                            })
                        )}
                        loading={loading}
                    />
                </UmerBox>

                <UmerBox>
                    <BlockTitle heading={'Detections'} />
                    {/* <ImageViewContainer
                        key="detections"
                        instanceKey="laksvelDetections"
                        isOptoScaleAdmin={true}
                        isColor={true}
                        images={[]}
                    />
                    <div>
                        <FishHealthFilter filterStateList={[]} onFilterGroupChange={() => {}} />
                    </div> */}
                </UmerBox>
            </div>
        </>
    );
};

export default Emaciation;
export { Emaciation };
