import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import UmerBox from '@/components/atoms/UmerBox';
import ScoreTable from '@/components/molecules/ScoreTable';
import { laksvelToTabularData } from '@/components/molecules/ScoreTable/ScoreTable';
import {
    defaultBarChartOptions,
    OptoBarChart,
    OptoMixedChart,
} from '@/components/organisms/OptoGraphs';
import {
    defaultLineChartOptions,
    LaksvelFirstImpressionLineChartMapper,
} from 'components/organisms/OptoGraphs/OptoMixedChart';
import { ChartActionButtons } from 'components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChart';
import Options, {
    autoTimeScale,
    categoryScale,
    chartTitle,
    minMax,
    formatAxsis,
    TypeNames,
    noDots,
    startYAxisAtZero,
    weightAndLengthUnitConverter,
} from '@/components/organisms/OptoGraphs/optionsModifiers';
import { LaksvelFirstImpressionBarChartMapper } from '@/components/organisms/OptoGraphs/OptoBarChart/OptoBarChartMappers';
import { useLaksvel } from '@/contexts/laksvel-context';

import { LaksvelRouterPageProps } from '../LaksvelContainerRouter';

import styles from '../Laksvel.module.scss';

const actionButtons = {
    copyAsPng: true,
    resetZoom: true,
    downloadAsPng: true,
    fullScreen: true,
    timeScaleSwitch: false,
};

const FirstImpression: FunctionComponent<LaksvelRouterPageProps> = () => {
    const { t } = useTranslation();

    const { loading, laksvelData, laksvelTrendData } = useLaksvel();

    const formattedData = (data) => {
        if (!data || typeof data !== 'object' || Object.keys(data).length === 0) {
            return [];
        }
        const keys = Object.keys(data);
        const days = data[keys[0]]?.trend?.map((entry) => entry?.day) || [];

        return days
            .map((day, index) => {
                const dayData = { day };

                keys.forEach((key) => {
                    let value = data[key]?.trend?.[index]?.percent ?? null;
                    // Set values below 0 to 0, and values above 100 to 100
                    if (value !== null) {
                        value = Math.min(Math.max(value, 0), 100);
                    }
                    dayData[key] = {
                        trend: value,
                    };
                });

                return dayData;
            })
            .reverse(); // Reverse the array to fix the order of the days
    };

    const formattedDataForTrend = formattedData(laksvelTrendData);

    const firstImnpressionBarChartDataset = LaksvelFirstImpressionBarChartMapper({
        data: laksvelData ?? [],
    });

    const [activeBox, setActiveBox] = useState<number>(1);
    return (
        <>
            <div className={styles.switchingButtons}>
                <button
                    type="button"
                    onClick={() => setActiveBox(1)}
                    className={activeBox === 1 ? styles.activeButton : ''}>
                    {t("Today's status")}
                </button>
                <button
                    type="button"
                    onClick={() => setActiveBox(2)}
                    className={activeBox === 2 ? styles.activeButton : ''}>
                    {t("Todays's status, bar chart")}
                </button>
                <button
                    type="button"
                    onClick={() => setActiveBox(3)}
                    className={activeBox === 3 ? styles.activeButton : ''}>
                    {t('Trend chart')}
                </button>
            </div>
            <div className={styles.laksvelPageDashBoardGrid_100}>
                {activeBox === 1 && (
                    <UmerBox>
                        <ScoreTable data={laksvelToTabularData(laksvelData ?? [])} />
                    </UmerBox>
                )}
                {activeBox === 2 && (
                    <UmerBox>
                        <OptoBarChart
                            chartData={firstImnpressionBarChartDataset}
                            chartName={t('First impression')}
                            loading={loading}
                            chartOptions={Options(defaultBarChartOptions).modify(
                                chartTitle({ title: t("Today's status") }),
                                categoryScale({
                                    labels: firstImnpressionBarChartDataset.labels,
                                }),
                                formatAxsis({
                                    axsisID: 'y',
                                    tickType: 'percent',
                                    descimals: 0,
                                })
                            )}
                        />
                    </UmerBox>
                )}
                {activeBox === 3 && (
                    <UmerBox>
                        <OptoMixedChart
                            chartName={t('Trend')}
                            chartData={LaksvelFirstImpressionLineChartMapper({
                                data: formattedDataForTrend,
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale({}),
                                chartTitle({ title: t('Trend') }),
                                weightAndLengthUnitConverter({
                                    type: TypeNames.percent,
                                    fromUnit: '%',
                                    toUnit: '%',
                                }),
                                startYAxisAtZero({}),
                                noDots(),
                                minMax({ min: 0, max: 100 }),
                                formatAxsis({
                                    axsisID: 'y',
                                    tickType: TypeNames.percent,
                                    descimals: 0,
                                })
                            )}
                            loading={loading}
                        />
                    </UmerBox>
                )}
            </div>
        </>
    );
};

export default FirstImpression;
export { FirstImpression };
